<template>
  <div>
    <h3>Estatuto social da empresa</h3>
    <span class="h5">Envie todas as folhas da última atualização do estatuto social assinado.</span>
    <validation-observer ref="SocialContractForm" #default="{ invalid }">
      <b-row>
        <b-col cols="12">
          <validation-provider #default="{ errors }" name="Procuração" vid="procuracao" rules="required">
            <cc-dropzone v-model="contrato_social" subtitle="Envie seu arquivo no formato PDF" acceptFormats=".pdf" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="12" class="d-flex flex-row justify-content-end">
          <div>
            <b-button variant="outline-primary" class="mr-1" @click="$emit('cancel')">Cancelar</b-button>
            <b-button variant="primary" :disabled="invalid" @click="save">
              Confirmar e enviar documentos
            </b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { BRow, BCol, BFormInput, BFormGroup, BButton, BModal, BFormCheckbox } from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CcDropzone from '@core/components/cc-dropzone/CcDropzone.vue'

export default {
  name: 'ContractForm',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    BFormCheckbox,
    ValidationObserver,
    ValidationProvider,
    CcDropzone
  },
  data() {
    return {
      contrato_social: null,
      confirmation: false
    };
  },
  methods: {
    async save() {
      try {
        this.$swal.showLoading()
        await this.$store.dispatch('company/uploadSocialContract', this.contrato_social)
        this.$swal.fire({
          title: 'Sucesso!',
          text: 'Dados salvos com sucesso!',
          icon: 'success'
        })
        this.$emit('saved')
      } catch (error) {
        console.log('error', error)
        this.$swal.fire({
          title: 'Erro!',
          text: 'Não foi possível salvar os dados!',
          icon: 'error'
        })
      }
    },
  },
};
</script>

<style lang="scss">
#socialContractValidationCheckbox + .custom-control-label::before,
#socialContractValidationCheckbox + .custom-control-label::after {
  top: 1rem;
}
</style>