<template>
  <b-card bg-variant="white" :border-variant="termAccepted ? 'success' : 'light'" class="w-100 h-100 min-w min-h" body-class="pr-0 pl-2">
    <template #default>
      <b-row align-v="center" align-h="center" class="w-100 h-100 min-w min-h pr-0 pl-2">
        <b-col sm="12" md="12" class="w-100">
          <b-row align-v="center" align-h="center">
            <b-col sm="12">
              <h5 v-if="!termAccepted">
                <feather-icon icon="AlertCircleIcon" class="text-warning mr-1" size="24" />{{
                  contractualDraftTitle
                }}
              </h5>
              <h5 v-else>
                <feather-icon icon="CheckCircleIcon" class="text-success mr-1" size="24" />{{
                  contractualDraftTitle
                }}
              </h5>
            </b-col>
          </b-row>
          <b-row v-if="termAccepted" align-v="center" align-h="between" class="pt-1 w-100">
            <b-col sm="12" md="6">
              <b-button variant="primary" @click="showModal()">
                Visualizar minuta
              </b-button>
            </b-col>
            <b-col sm="12" md="6">
              <b-button variant="outline-primary" @click="downloadMinuta()">
                <feather-icon icon="DownloadIcon" class="text-primary" /> Baixar minuta</b-button>
            </b-col>
          </b-row>
          <b-row v-else align-v="center" align-h="center" class="pt-1 w-100">
            <b-col sm="12" md="12">
              <b-button block variant="primary" @click="showModal()">
                Visualizar minuta
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <draft-viewer-modal ref="contractualDraftModal"
                            :file="contractualDraftFile"
                            :type="contractualDraftType"
                            @term-accepted="handleAccept()"
        />
      </b-row>
    </template>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import DraftViewerModal from '@/views/client/user/components/DraftViewerModal.vue'
import { downloadMinutaButton } from '@core/comp-functions/misc/dom'

export default {
  name: 'DraftViewerCard',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    DraftViewerModal,
  },
  props: {
    contractualDraftFile: {
      type: String,
      default: '',
    },
    contractualDraftTitle: {
      type: String,
      default: '',
    },
    contractualDraftType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      termAccepted: false,
    }
  },
  methods: {
    showModal() {
      this.$refs.contractualDraftModal.$refs.contractualDraftModal.show()
    },
    downloadMinuta() {
      downloadMinutaButton(this.contractualDraftFile, this.contractualDraftType)
    },
    handleAccept() {
      this.termAccepted = true
      this.$emit('checked')
    },
  },
}
</script>

<style lang="scss">

.min-w {
  min-width: 325px;
}

.min-h {
  min-height: 125px;
}
</style>
