<template>
  <b-modal
    ref="contractualDraftModal"
    centered
    size="lg"
    header-class="border-bottom"
    header-bg-variant="white"
    @ok="handleAccept"
  >
    <template #modal-header="{ close }">
      <div class="d-flex justify-content-between align-items-center py-2 w-100">
        <h5>Leia com atenção o documento abaixo</h5>
        <b-button size="sm" variant="primary" @click="downloadMinuta()">
          <feather-icon icon="DownloadIcon" class="text-white mr-1" />Baixar Minuta
        </b-button>
      </div>
    </template>
    <template #default>
      <vue-scrollbar
        ref="Scrollbar"
        :style="scrollbar"
        :onMaxScroll="handleMaxScroll"
        class="p-3"
      >
        <vue-pdf-embed :source="file" />
      </vue-scrollbar>
    </template>
    <template #modal-footer="{ ok, cancel, hide }">
      <div class="d-flex justify-content-between align-items-center">
        <b-form-group class="mt-1">
          <b-form-checkbox
            v-model="accepted"
            name="checkbox-minuta"
            id="checkbox-minuta"
            :disabled="!canCheckMinuta"
          >
            Confirmo que revisei o documento e estou de acordo com os termos, e ciente que
            esse não poderá ser alterado no futuro.
          </b-form-checkbox>
        </b-form-group>
        <div class="d-flex flex-row align-items-start justify-content-end p-0 w-100">
          <b-button variant="outline-primary" class="mr-1" @click="cancel()"
            >Cancelar</b-button
          >
          <b-button variant="primary" @click="ok()" :disabled="!accepted"
            >Estou de acordo</b-button
          >
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BFormGroup, BFormCheckbox, BButton } from "bootstrap-vue";
import VueScrollbar from "vue2-scrollbar";
import "vue2-scrollbar/dist/style/vue2-scrollbar.css";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import { downloadMinutaButton } from "@core/comp-functions/misc/dom.js";

export default {
  name: "DraftViewerModal",
  props: {
    file: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  components: {
    BModal,
    VuePdfEmbed,
    VueScrollbar,
    BFormCheckbox,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      accepted: false,
      canCheckMinuta: false,
      scrollbar: {
        maxHeight: "300px",
        width: "100%",
      },
    };
  },
  methods: {
    handleMaxScroll(event) {
      if (event.bottom === true) this.canCheckMinuta = true;
    },
    downloadMinuta() {
      downloadMinutaButton(this.file, this.type);
    },
    handleAccept() {
      this.$emit("term-accepted");
    },
  },
};
</script>
