
const downloadMinutaButton = (path, name) => {
    var link = document.createElement("a");
    link.setAttribute("download", name);
    link.href = path;
    document.body.appendChild(link);
    link.click();
    link.remove();
}

export {
    downloadMinutaButton
}