<template>
  <b-container>
    <b-row align-v="start" class="p-2">
      <h4><strong class="text-primary">Leia com atenção os documentos abaixo.</strong></h4>
      <span>
        Verifique com atenção os documentos disponibilizados abaixo. É mandatório que você
        revise cada documento e concorde com os termos dos mesmos, uma vez que serão os
        documentos que irão governar as transações de empréstimos futuros.
      </span>
      <p class="mt-2">
        <strong class="text-primary">
          Importante: Estes são documentos padrões e não poderão ser alterados no
          futuro.
        </strong>
      </p>
      <b-row align-v="center" align-h="between" class="w-100">
        <b-col md="9" class="p-0">
          <b-row align-v="center" align-h="between" class="w-100">
            <b-col sm="12" md="6" class="w-100">
              <draft-viewer-card
                contractual-draft-title="Minuta de Prestação de
      Serviços"
                contractual-draft-file="/Minuta+Contrato+Prestação+Serviços.pdf"
                contractual-draft-type="minuta_prestacao_servicos"
                @cancel="$emit('cancel')"
                @checked="minuta_prestacao_servicos = true"
              />
            </b-col>
            <b-col sm="12" md="6" class="w-100">
              <draft-viewer-card
                contractual-draft-title="Minuta da Nota Comercial"
                contractual-draft-file="/Modelo+de+Termo+de+Emissão+de+Notas+Comerciais.pdf"
                contractual-draft-type="minuta_nota_comercial"
                @cancel="$emit('cancel')"
                @checked="minuta_nota_comercial = true"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col sm="12" md="3" align-self="end">
          <b-row align-v="end" align-h="end">
            <b-col sm="12">
              <b-button variant="primary" size="md" :disabled="!canContinue" @click="enviarDocumentacaoParaAnalise()">
                Concordo e quero prosseguir
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    <!-- <minuta-card :minutaFile="minutaFile" :minutaTitle="minutaTitle" class="mt-2" @checked="enviarDocumentacaoParaAnalise()" @cancel="$emit('cancel')" /> -->
    </b-row>
  </b-container>
</template>

<script>
import {
  BRow, BCol, BButton, BContainer,
} from 'bootstrap-vue'
// import MinutaCard from '@/views/client/user/components/MinutaCard.vue'
import DraftViewerCard from '@/views/client/user/components/DraftViewerCard.vue'

export default {
  name: 'Minuta',
  components: {
    // BCard,
    BRow,
    BCol,
    BButton,
    // MinutaCard,
    DraftViewerCard,
    BContainer,
  },
  data() {
    return {
      minuta_prestacao_servicos: false,
      minuta_nota_comercial: false,
    }
  },
  computed: {
    canContinue() {
      return this.minuta_prestacao_servicos && this.minuta_nota_comercial
    },
  },
  methods: {
    async enviarDocumentacaoParaAnalise() {
      await this.$store.dispatch(
        'company/enviarDocumentacaoParaAnalise',
        this.$store.state.auth.userData.empresa.id,
      )
      this.$emit('checked')
    },
  },
}
</script>
