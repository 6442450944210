<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <AppPageHeader :title="title" :subtitle="subTitle">
        <template slot="header-left">
          <div class="d-flex flex-column align-content-end align-items-end">
            <b-button
              variant="outline-primary"
              class="w-fit mb-1"
              @click="goBack"
            >Voltar</b-button>
            <b-progress max="100" height="16px" class="w-100">
              <b-progress-bar
                :value="currentProgress"
                :label="`${currentProgress}%`"
                class="progress-bar-dark"
                :variant="variant"
              />
            </b-progress>
          </div>
        </template>
      </AppPageHeader>
    </b-card>

    <b-card>
      <validation-observer ref="loginForm" #default="{}">
        <b-form class="auth-registration-form" @submit.prevent="login">
          <company-form v-if="step === 0" @updated="nextStep" />

          <div v-if="step === 1">
            <div
              v-if="!hasSubscribers"
              class="d-flex flex-row justify-content-end align-items-center p-0 mb-1"
            >
              <b-alert show variant="info" class="p-1 mb-0 border-info">
                <feather-icon icon="UserIcon" size="18" class="mr-1" />
                <b>Atenção. </b>É necessário adicionar pelo menos um representante legal e um avalista ou um representante avalista antes de continuar.
              </b-alert>
            </div>

            <h3
              v-if="$store.state.company.current.representantes_legais.length"
              class="mb-1"
            >
              Representantes da Empresa
            </h3>
            <representative-card
              v-for="representante in $store.state.company.current.representantes_legais"
              :key="representante.id"
              :representative-data="representante"
              editable
              @saved="retryCheckNecessarySubscribers"
              @deleted="retryCheckNecessarySubscribers"
            />

            <div v-if="!isCreating" class="d-flex justify-content-between">
              <b-button variant="outline-primary"
                        @click="addRepresentative"
              >Adicionar novo assinante <feather-icon icon="PlusIcon" size="16" />
              </b-button>
              <div class="d-flex">
                <b-button
                  variant="outline-primary mr-1"
                  @click="this.$router.push({ name: 'dashboard' })"
                >
                  Cancelar
                </b-button>
                <b-button
                  variant="primary"
                  :disabled="!hasSubscribers"
                  @click="nextStep"
                >
                  Próximo
                </b-button>
              </div>
            </div>
            <representative-form
              v-show="isCreating && step === 1"
              ref="addRepresentativeForm"
              @cancel="showCreateForm = false"
              @saved="retryCheckNecessarySubscribers"
              @deleted="checkNecessarySubscribers"
            />
          </div>

          <contract-form
            v-if="step === 2"
            @saved="nextStep"
            @cancel="$router.push({ name: 'dashboard' })"
          />

          <minuta
            v-if="step === 3"
            @checked="nextStep"
            @cancel="$router.push({ name: 'dashboard' })"
          />

          <div
            v-if="step === 4"
            class="d-flex justify-content-between align-items-center p-1 w-100"
          >
            <div class="d-flex flex-row justify-content-center align-items-center w-100">
              <b-img src="@/assets/images/registration-completed.svg" class="mr-2" />
              <div class="d-flex flex-column align-items-start w-100">
                <h1>Suas informações estão em processo de análise</h1>
                <span class="h3">Fique tranquilo, em breve sua documentação será validada. Este processo
                  ocorre somente no seu primeiro pedido. Nas próximas vezes, pediu,
                  recebeu!
                </span>
              </div>
            </div>
            <div class="d-flex flex-row align-items-center justify-content-end text-nowrap">
              <b-button
                variant="primary"
                class="ml-1 text"
                @click="$router.push({ name: 'dashboard.credit' })"
              >Ir para o início</b-button>
            </div>
          </div>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BCard,
  // BRow,
  // BCol,
  // BBadge,
  // BFormGroup,
  BAlert,
  // BFormInput,
  // BInputGroupAppend,
  // BInputGroup,
  // BFormCheckbox,
  BProgress,
  BImg,
  BForm,
  BButton,
  BProgressBar,
} from 'bootstrap-vue'
import { required, email } from '@validations'
// import CcDropzone from '@core/components/cc-dropzone/CcDropzone.vue'
import AppPageHeader from '@core/components/app-page-header/AppPageHeader.vue'
import CompanyForm from '@/views/client/user/components/CompanyForm.vue'
import RepresentativeCard from '@/views/client/user/components/RepresentativeCard.vue'
import RepresentativeForm from '@/views/client/user/components/RepresentativeForm.vue'
// import BankAccountCard from '@/views/client/user/components/BankAccountCard.vue'
// import BankAccountForm from '@/views/client/user/components/BankAccountForm.vue'
import ContractForm from '@/views/client/user/components/ContractForm.vue'
import Minuta from '@/views/client/user/components/Minuta.vue'

export default {
  name: 'CompleteRegistration',
  components: {
    BCard,
    // BRow,
    // BCol,
    // BBadge,
    // BFormGroup,
    // BFormInput,
    // BInputGroupAppend,
    // BInputGroup,
    // BFormCheckbox,
    CompanyForm,
    RepresentativeCard,
    RepresentativeForm,
    BProgress,
    BImg,
    BForm,
    BButton,
    BProgressBar,
    // ValidationProvider,
    ValidationObserver,
    // CcDropzone,
    BAlert,
    AppPageHeader,
    // BankAccountCard,
    // BankAccountForm,
    ContractForm,
    Minuta,
  },
  data() {
    return {
      showCreateForm: false,
      required,
      email,
      step: 0,
      barProgress: 20,
      haveAllNecessarySubscribers: false,
    }
  },
  computed: {
    currentStep() {
      return this.step
    },
    currentProgress() {
      return this.barProgress
    },
    isCreating() {
      return (
        this.currentCompany.representantes_legais.length === 0 || this.showCreateForm
      )
    },
    title() {
      let title = 'Documentos'
      if (this.step === 2) { title = 'Documentos da empresa' } else if (this.step === 4) { title = 'Cadastro concluído' }
      return title
    },
    subTitle() {
      if (this.step === 2) {
        return 'Anexe e envie os documentos requisitados.'
      }

      if (this.step === 3 || this.step === 4) {
        return 'Informações enviadas com sucesso.'
      }

      return 'Informe abaixo seus dados cadastrais.'
    },
    variant() {
      return this.step === 4 ? 'success' : 'primary'
    },
    currentCompany() {
      return this.$store.state.company.current
    },
    hasSubscribers() {
      return this.step === 1 && this.haveAllNecessarySubscribers === true
    },
  },
  methods: {
    async nextStep() {
      this.step += 1
      if (this.step === 1) await this.checkNecessarySubscribers(this.currentCompany.id)
      this.barProgress += 20
    },
    async goBack() {
      if (this.step === 0) {
        await this.$router.push({ name: 'concessoes.index'})
      } else {
        this.step -= 1
        if (this.step === 1) await this.checkNecessarySubscribers(this.currentCompany.id)
        this.barProgress -= 20
      }
    },
    addRepresentative() {
      this.showCreateForm = true
    },
    async retryCheckNecessarySubscribers() {
      this.showCreateForm = false
      await this.checkNecessarySubscribers(this.currentCompany.id)
    },
    async checkNecessarySubscribers(id) {
      if (this.currentCompany.representantes_legais.length === 0) {
        this.haveAllNecessarySubscribers = false
        return
      }
      try {
        const subscribers = await this.$store.dispatch('company/checkSubscribers', id)
        this.haveAllNecessarySubscribers = subscribers.data.valid
      } catch (error) {
        this.haveAllNecessarySubscribers = false
      }
    },
  },
}
</script>
